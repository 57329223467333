<template>
  <!-- 订单账单 -->
  <div class="wrapper">
    <div class="header">
      <admin-title title="账单"></admin-title>
    </div>

    <el-descriptions style="margin-bottom:20px;" direction="vertical" :column="8" border :title="`订单号：${orderNo}`">
      <el-descriptions-item label="客户名" label-class-name="my-label">{{ member.memberName }}</el-descriptions-item>
      <el-descriptions-item label="商品描述" label-class-name="my-label">{{ orderGoods.skuTitle }}</el-descriptions-item>
      <el-descriptions-item label="规格" label-class-name="my-label">
        <div v-for="(sku, i) in orderGoods.skuAttrList" :key="i">
          <span>{{ sku.group }}：{{ sku.value }}</span>
        </div>
      </el-descriptions-item>
      <el-descriptions-item label="数量" label-class-name="my-label">{{ orderInfo.totalOrderNum }}</el-descriptions-item>
      <el-descriptions-item label="总租金" label-class-name="my-label">{{ orderInfo.rentPrice }}</el-descriptions-item>
      <el-descriptions-item label="买断金" label-class-name="my-label">{{ orderInfo.buyoutPrice }}</el-descriptions-item>
      <el-descriptions-item label="租期" label-class-name="my-label">{{ orderInfo.billPeriodNum }} 期</el-descriptions-item>
    </el-descriptions>
    <!-- <tp-table @handleSelectionChange="handleSelectionChange" :showPagination="false" :isCheckBox="true" :tableData="billList" :columns="columns" /> -->
    <el-table ref="billtable" :data="billList" style="width: 100%" header-row-class-name="tp-table__header" @selection-change="handleSelectionChange">
      <el-table-column align="center" type="selection" width="width" :selectable="selectable"> </el-table-column>
      <el-table-column align="center" prop="billNum" label="期数"> </el-table-column>
      <el-table-column align="center" prop="billDate" label="账期"> </el-table-column>
      <el-table-column align="center" prop="principalAmount" label="金额"> </el-table-column>
      <el-table-column align="center" prop="offAmount" label="减免金额"> </el-table-column>
      <el-table-column align="center" prop="overdueAmount" label="逾期金额"> </el-table-column>
      <el-table-column align="center" prop="leftPayAmount" label="待结金额"> </el-table-column>
      <el-table-column align="center" prop="paidAmount" label="实付金额"> </el-table-column>
      <el-table-column align="center" prop="statusName" label="状态"> </el-table-column>
    </el-table>
    <div class="footer">
      <div class="left">
        <el-checkbox v-model="isAll" @change="changeAll">全选</el-checkbox>
        <div class="selected">
          已勾选 {{ selectedTable.length }} 期，待结金额总计：<span>￥{{ leftPayAmount }}</span>
        </div>
      </div>
      <div class="right">
        <el-button type="" size="small" @click="cancel">关 闭</el-button>
      </div>
    </div>
    <div style="height:50px;"></div>
  </div>
</template>

<script>
import { orderStatus, dict_overdue_level } from '@/enum/dict.js'
import { getOrderBillListAPI, } from './api'
import { authBtnMixin } from '@/mixins/authBtnMixin'
export default {
  name: 'OrderBill',
  mixins: [authBtnMixin],
  data() {
    return {
      dialogVisible: false,
      selectedTable: [],
      isAll: false,
      orderInfo: {},
      billList: [],
      orderGoods: {},
      orderNo: '',
      member: {},
      orderStatus,
      dict_overdue_level,
      overdueData: {
        orderNo: '',
        overdueLevel: ''
      }
    }
  },
  provide() {
    return {
      context: this
    }
  },
  created() {
    this.getOrderBillList()
  },
  computed: {
    leftPayAmount() {
      let total = 0
      this.selectedTable.forEach(item => {
        total = total + Number(item.leftPayAmount)
      })
      return total.toFixed(2)
    },
    is_overdue() {
      return this.billList.some(item => item.overdueAmount - 0 > 0)
    }
  },
  mounted() {},
  methods: {
    async getOrderBillList() {
      const res = await getOrderBillListAPI(this.$route.params.id)
      this.orderInfo = res
      this.orderNo = res.orderNo
      this.member = res.member
      this.orderGoods = res.orderGoods || {}
      this.billList = res.billList || []
    },
    // 勾选项改变
    handleSelectionChange(arr) {
      const list = this.billList.filter(item => item.status != 2)
      this.selectedTable = arr
      if (list.length == this.selectedTable.length) {
        this.isAll = true
      } else {
        this.isAll = false
      }
    },
    cancel() {
      // this.$router.push({ name: 'OrderList' })
      window.close()
      // this.$confirm('取消后将不可逆，已支付的款项将原路退回，是否取消?', '提示', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning'
      // })
      //   .then(() => {
      //     this.$message({
      //       type: 'success',
      //       message: '操作成功!'
      //     })
      //   })
      //   .catch(() => {
      //     this.$message({
      //       type: 'info',
      //       message: '已取消操作'
      //     })
      //   })
    },
    // 是否全选
    changeAll() {
      this.isAll ? this.$refs.billtable.toggleAllSelection() : this.$refs.billtable.clearSelection()
    },
    selectable(row, index) {
      if (row.status == 2) {
        return false
      } else {
        return true
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .el-descriptions {
    .my-label {
      color: #1a1e25;
      background: #fafafa;
    }
    .my-content {
      .handle {
        display: flex;
        justify-content: space-between;
        & > span {
          &:first-child {
            margin-right: 20px;
            flex: 1;
          }
        }
      }
    }
  }
  .footer {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      line-height: 22px;
      .selected {
        span {
          color: #ff2600;
        }
      }
    }
    .right {
      padding-right: 20px;
    }
  }
  .tp-table__header {
    height: 48px;
    th {
      font-size: 14px;
      font-weight: 400;
      // color: #626e7c;
      color: #1a1e25;
      background-color: #fafafa;
      // border-top: 1px solid #ccc;
      // border-bottom: 1px solid #ccc;
      // padding-left: 10px;
    }
  }
}
</style>
